import * as React from "react"
import {ChakraProvider, CSSReset} from "@chakra-ui/react"
import theme from "./src/@chakra-ui/gatsby-plugin/theme"; // Ensure the path is correct


export const wrapRootElement = ({ element }) => (
    <ChakraProvider theme={theme}>
        <CSSReset />
        {element}
    </ChakraProvider>
)